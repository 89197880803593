<template>
  <div class="staff">
    <div class="left-top">
      <el-button type="primary" @click="backpage()" style="margin-right: 20px">返回</el-button>
      <span style="font-size: 18px; font-weight: 400; color: #333">问题列表</span>
      <el-divider></el-divider>
      <!-- 查询 -->
      <el-form :model="searchForm" :inline="true" ref="searchForm" class="searchForm" >
        <el-form-item label="问题标题:" prop="topicCategoryName">
          <el-input
            style="width: 200px"
            v-model="searchForm.topicCategoryName"
            placeholder="输入关键词搜索"
          ></el-input>
        </el-form-item>
        <el-form-item label="所属分类:">
          <el-cascader
            style="width: 250px"
            v-model="choosetopic"
            :options="options"
            @change="handleChange"
          ></el-cascader>
        </el-form-item>
        <el-form-item>
          <el-button @click="keysSearch(1)">
            <i class="el-icon-search" style="margin-right: 5px"></i>
            搜索
          </el-button>
          <el-button @click="resetForm()">
            <i class="el-icon-refresh-right" style="margin-right: 5px"></i>
            重置
          </el-button>
        </el-form-item>
        <el-form-item>
          <el-button type="primary" @click="submitForm" style="width: 150px">
            提交
          </el-button>
        </el-form-item>
      </el-form>
    </div>
    <!-- 表格 -->
    <el-table :data="PageConfigure" style="width: 100%">
      <el-table-column fixed="left" label="选择" width="80" align="center">
        <template slot-scope="scope">
          <el-checkbox
            :disabled="scope.row.choose"
            v-model="scope.row.IsConfigurated"
            @change="check(scope.row)"
            label="选择"
          ></el-checkbox>
        </template>
      </el-table-column>
      <el-table-column fixed label="问题标题" prop="Title" align="center">
      </el-table-column>
      <el-table-column
        label="所属分类"
        width="300"
        prop="HelpCenterCategoryName"
        align="center"
      >
        <template slot-scope="scope">
          <span v-show="scope.row.HelpCenterCategoryName">{{
            scope.row.HelpCenterCategoryName
          }}</span>
          <span v-show="!scope.row.HelpCenterCategoryName">-</span>
        </template></el-table-column
      >
      <el-table-column
        label="创建时间"
        width="180"
        prop="CreateTime"
        align="center"
      ></el-table-column>
    </el-table>
    <div class="task-list-page" v-if="PageConfigure !== ''">
      <el-pagination
        @current-change="OnCurrentPageChanged"
        @size-change="handleSizeChange"
        :current-page="Params.PageIndex"
        :page-sizes="[10, 20, 30, 40, 50, 60]"
        layout="prev, pager, next,sizes,jumper,total"
        :page-size="Params.PageSize"
        background
        :total="Params.TotalCount"
      >
      </el-pagination>
    </div>
    <!-- 编辑 -->
  </div>
</template>
<script>
export default {
  name: "Aboutques",
  data() {
    var numberRules = (rule, value, callback) => {
      if (/^[0-9]\d*$/.test(value)) {
        callback();
      } else {
        callback(new Error("请输入整数"));
      }
    };
    return {
      isimg: "",
      dialongtitle: "",
      dialogFormVisible: false,
      formLabelWidth: "120px",
      Rowcontent: {
        CategoryName: "",
        Description: "",
        SortNumber: "",
        ParentCategoryId: 0,
        AppImg: "",
      },
      rules: {
        CategoryName: [
          { required: true, message: "请完善信息", trigger: "blur" },
        ],
        // description: [
        //   { required: true, message: "请完善信息", trigger: "blur" },
        // ],
        // img: [{ required: true, message: "请完善信息", trigger: "blur" }],
        // parentTopicCategoryId: [
        //   { required: true, message: "请完善信息", trigger: "blur" },
        // ],
        SortNumber: [
          { required: true, message: "请完善信息", trigger: "blur" },
          { validator: numberRules, trigger: "blur" },
        ],
      },
      PageConfigure: [], //列表分页数据
      aboutpage: {},
      Params: {
        PageIndex: 1,
        PageSize: 10,
        TotalCount: 5,
        TotalPage: 0,
      },
      IsEdit: false,
      //查询数据
      searchForm: {
        topicCategoryName: "",
      },

      //选取
      choosetopic: [],
      // Alltopic: [{ value: -1, label: "全部" }],
      options: [
        {
          value: "-1",
          label: "全部",
        },
      ],
      releasetime: "", //发布时间
      parentValue: "",
      typeValue: "",
      searchContent: {
        KeyWords: "",
        CategoryId: -1,
      },
      img_Url:
        "https://file.1renshi.com/FileHandler.ashx?module=article&type=image",
      defaultimg: require("../../assets/images/defaultimg.jpg"),
      imgshow: "1",
      //解决重复点击问题
      isDisabled: false,
      enableArr: [],
      submitpage: {
        Ids: "",
        QuestionId: "",
      },
    };
  },
  created() {
    this.searchDate();
    this.getalltype();
  },
  watch: {
    enableArr(n, o) {
      if (n.length == 6) {
        this.closecheck();
        this.$message({
          message: "只能勾选6个问题,请先取消一些",
          type: "warning",
          duration: "1500",
        });
      } else {
        this.PageConfigure.forEach((item) => {
          item.choose = false;
        });
      }
    },
  },
  methods: {
    //刷新
    refresh() {
      this.$router.go(0);
    },
    //获取选择数据
    getChooseList(){
      let arr = this.submitpage.son.split("_");
      this.enableArr = arr.map(Number);
      console.log(this.enableArr);
    },

    //获取数据
    searchDate() {
      var _this = this;
      this.Params.QuestionId = this.$route.query.id;
      this.submitpage.QuestionId = this.$route.query.id;
      this.submitpage.son = this.$route.query.son;
      // this.aboutpage.QuestionId=this.$route.query.id;
      this.$get(_this.$api.GetAdminQuestionList, { ..._this.Params })
        .then((res) => {
          _this.PageConfigure = res.Data;
          console.log(this.PageConfigure);
          console.log(res);
          _this.Params.TotalCount = res.TotalNumber;
          _this.Params.TotalPage = res.TotalPage;
          _this.PageConfigure.forEach((item) => {
            _this.$set(item, "choose", false);
          });
          _this.Params.PageIndex = 1;
          _this.getChooseList();
        })
        .catch((err) => {
          console.log(err);
        });
    },
    //多选
    check(val) {
      let _this = this;
      if (val.IsConfigurated) {
        this.enableArr.push(val.HelpCenterQuestionId);
        val.IsConfigurated = true;
      } else {
        this.enableArr.forEach((item, index) => {
          if (item == val.HelpCenterQuestionId) {
            _this.enableArr.splice(index, 1);
          }
        });
        val.IsConfigurated = false;
      }
      console.log(_this.enableArr);
    },
    Topicselect(val) {
      console.log(val);
    },
    //获取所属分类
    getalltype() {
      let _this = this;
      this.$get(this.$api.GetAllTopHelpCenterCategor).then((res) => {
        console.log(res.Data);
        if (res.Code == 200) {
          res.Data.forEach((item, index) => {
            _this
              .$get(
                _this.$api.GetNextHelpCategorsByParentId +
                  "?ParentCategoryId=" +
                  item.HelpCenterCategoryId
              )
              .then((val) => {
                let a = [];
                val.Data.forEach((name) => {
                  a.push({
                    value: name.HelpCenterCategoryId,
                    label: name.HelpCenterCategoryName,
                  });
                });
                _this.options.push({
                  value: item.HelpCenterCategoryId,
                  label: item.HelpCenterCategoryName,
                  children: a,
                });
              });
          });
        }
      });
    },
    //话题选取
    handleChange(value) {
      console.log(value);
      // console.log(this.choosetopic);
    },
    //选择
    seltype() {},
    //新增、编辑的提交
    entime() {
      setTimeout(() => {
        this.isDisabled = false;
      }, 500);
    },
    submitForm() {
      let _this = this;
      this.entime();
      if (this.enableArr.length == 0) {
        this.$message({
          message: "选择不能为空",
          type: "warning",
          duration: "1500",
        });
        return;
      }
      let a = this.enableArr.slice(0,6).toString();
      let id = a.replace(/,/g, "_");
      console.log(id)
      // this.enableArr.forEach((item) => {
      //   _this.submitpage.Ids = _this.submitpage.Ids.concat(`Ids=${item}&`);
      // });
      // console.log(_this.submitpage.Ids);
      // let arr=JSON.parse( _this.submitpage.Ids);
      // _this.submitpage.Ids=arr;
      
      this.$post(this.$api.ConfigurateRelatedQuestion +`?ids=${id}&QuestionId=${_this.submitpage.QuestionId}`).
      then((res) => {
        if (res.IsSuccess) {
          _this.submitpage.Ids = "";
          this.$message({
            message: "提交成功",
            type: "success",
            duration: "1500",
          });
        }
      });
    },

    //获取分页
    OnCurrentPageChanged(val) {
      this.Params.PageIndex = val;
      //console.log(this.Params.PageIndex);
      this.keysSearch();
    },
    handleSizeChange(val) {
      this.Params.PageSize = val;
      //console.log(val);
      this.keysSearch();
    },

    //超6禁止
    closecheck() {
      let _this = this;
      _this.PageConfigure.forEach((item) => {
        if (item.IsConfigurated == false) {
          item.choose = true;
        }
      });
    },
    //编辑 上级分类

    //查询
    keysSearch(num) {
      var _this = this;
      this.searchContent.QuestionId = this.$route.query.id;
      if (
        _this.searchForm.topicCategoryName != null &&
        _this.searchForm.topicCategoryName.trim() != ""
      ) {
        _this.searchContent.KeyWords = this.searchForm.topicCategoryName.trim();
      }
      if (this.choosetopic[0] !== -1) {
        this.searchContent.CategoryId = this.choosetopic[1];
      } else {
        this.searchContent.CategoryId = -1;
      }
      if (num == 1) {
        this.Params.PageIndex = 1;
      }
      this.$get(this.$api.GetAdminQuestionList, {
        ...this.searchContent,
        ...this.Params,
      }).then((res) => {
        _this.PageConfigure = res.Data;
        _this.PageConfigure.forEach((item) => {
          _this.$set(item, "choose", false);
          let a = _this.enableArr.indexOf(Number(item.HelpCenterQuestionId));
          // console.log(item)
          if (a == -1) {
            item.IsConfigurated = false;
          } else {
            item.IsConfigurated = true;
          }
        });
        if (_this.enableArr.length == 6) {
          _this.closecheck();
        }
        _this.searchContent = {};
        _this.Params.TotalCount = res.TotalNumber;
        _this.Params.TotalPage = res.TotalPage;
      });
    },
    //删除

    //重置查询
    resetForm() {
      this.searchForm.topicCategoryName = "";
      this.Params.PageIndex = 1;
      this.choosetopic = [];
      this.searchDate();
    },
    backpage() {
      this.$router.push({ path: "/Qadministration" });
    },
  },
};
</script>

<style lang="stylus" scoped>
/deep/.is-center.is-leaf {
  background: #e6e6e6;
  color: #303133;
}

/deep/.has-gutter tr {
  th {
    background: #e6e6e6;
    color: #303133;
  }

  td {
    text-align: center;
  }
}

.staff {
  background: #fff;
  padding: 10px 10px;
  border-radius: 10px;

  .searchForm {
    .el-form-item {
      vertical-align: middle;
      margin-top: 13px;
      margin-right: 30px;
    }
  }

  .backSpan {
    padding: 0px 12px;
    cursor: pointer;
  }

  .left-top {
    margin-bottom: 10px;

    .el-divider--horizontal {
      margin: 10px 0;
    }

    button {
      margin-left: 10px;
    }
  }

  /deep/.el-table .cell {
    white-space: nowrap; /* 不换行 */
  }

  .el-table .cell span {
    overflow: hidden; /* 超出部分隐藏 */
    white-space: nowrap; /* 不换行 */
    text-overflow: ellipsis; /* 超出部分文字以...显示 */
  }

  .task-list-page {
    margin-top: 10px;
  }
}

/* 编辑框样式 */
/deep/.edit-form {
  width: 500px;

  .el-dialog__header {
    height: 50px;
    text-align: center;
    background-color: #409eff;

    .el-dialog__title {
      line-height: 0px;
      font-size: 22px;
      color: #fff;
    }

    .el-dialog__headerbtn {
      display: none;
    }
  }

  .el-form-item .el-textarea__inner {
    resize: none;
  }

  .el-dialog__body {
    padding-bottom: 5px !important;
  }

  .dialog-footer {
    text-align: center;

    .el-button {
      background-color: #fff;
      color: #606266;
      border: 1px solid #dcdfe6;
    }

    .el-button:hover {
      background-color: #409eff;
      border-color: #409eff;
      color: #fff;
    }
  }

  img.upImg {
    width: 40px;
    height: 40px;
    background-size: 100% 100%;
    float: left;
  }
}

// 小星星
.xiaoxx {
  color: #F56C6C;
  position: absolute;
  top: 0;
  left: -10px;
}
</style>